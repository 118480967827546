.h-nosotros {
  padding: $space-5 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &--padding-small {
    padding: $space-3 0;
    @media screen and (max-width: 480px) {
      padding: $space-2 0;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__descripcion {
    font-size: $text-body-big;
    @media screen and (max-width: 767px) {
      font-size: $font-size-bigger;
    }
  }
}

.destacado {
  padding: $space-3 0;
  &__content {
  }

  &__descripcion {
    font-size: $display-2;
    font-weight: $font-weight-bolder;
    line-height: 1.2;
    @media screen and (max-width: 767px) {
      font-size: $display-3;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-1-res;
    }
    @media screen and (max-width: 480px) {
      font-size: $header-2;
    }
    strong {
      font-weight: $font-weight-bolder;
      color: $color-accent-2;
    }
  }
}

.h-piedras {
  padding: $space-3 0;
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__descripcion {
    font-size: $text-body-big;
    font-weight: $font-weight-normal;
    @media screen and (max-width: 767px) {
      font-size: $font-size-bigger;
    }
  }

  &__actions {
    &--desktop {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    &--mobile {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  &__right {
    padding-left: $space-3;
    @media screen and (max-width: 767px) {
      padding-left: 0;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.h-trayectoria {
  padding: $space-5 0;
  background-color: $color-grey-6;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__content {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr 3fr;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 6fr;
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }

  &__left {
    position: relative;
    top: 3rem;
    @media screen and (max-width: 991px) {
      left: 15rem;
    }
    @media screen and (max-width: 767px) {
      left: 13rem;
    }
    @media screen and (max-width: 575px) {
      display: none;
    }
  }

  &__left-item {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36rem;
    font-weight: $font-weight-bold;
    color: $color-accent-2;
    transition: $trans;
    z-index: 0;
    @media screen and (max-width: 767px) {
      font-size: 32rem;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }

  &__right-item {
    padding: $font-size 0;
    border-top: 2px solid $color-black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $text-body-big;

    &:nth-last-child(1) {
      border-bottom: 2px solid $color-black;
    }
  }

  &__right-num {
    @media screen and (max-width: 575px) {
      display: none;
    }
  }
}

.h-trayectoria__right-item:nth-child(n):hover {
  color: $color-accent-2;
  .h-trayectoria__left-item:nth-child(n) {
    opacity: 1;
  }
}

.h-proyectos {
  position: relative;
  background-color: $color-grey-6;
  &__items {
    // height: 620px;
  }

  &__item {
    position: relative;
    padding: 0 0.75rem;
    width: max-content !important;
    &:hover {
      .h-proyectos__item-overlay {
        opacity: 1;
        transition: $trans;
      }
    }
  }

  &__item-image {
    position: relative;
    width: max-content;
    img {
      // aspect-ratio: 16 / 12;
      height: 600px;
      width: auto;
      object-fit: cover;
    }
  }

  &__item-overlay {
    position: absolute;
    width: calc(100% - 1.5rem);
    height: 100%;
    top: 0;
    left: 0.75rem;
    padding: 0 $space-2;
    background-color: rgba($color-black, 0.15);
    opacity: 0;
    transition: $trans;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &__item-info {
    background-color: $color-white;
    padding: $space-05 $space-1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    color: $color-black;
    > div {
      width: 100%;
      border-bottom: 1px solid $color-black;
      padding: 0.25rem 0;
      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }

  &__item-header {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: $space-01;
  }

  &__item-titulo {
    font-size: $header-3;
    font-weight: $font-weight-bold;
  }

  &__item-acabado {
    color: $color-accent-2;
  }

  &__item-localizacion {
  }

  &__item-anyo {
  }
}

.h-clientes {
  padding: $space-5 0;
  background-color: $color-grey-6;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }

  &__content {
  }

  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $space-3;
    flex-wrap: wrap;
  }

  &__item {
    img {
      width: 80px;
      height: auto;
    }
  }
}
