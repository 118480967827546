.cta {
  background-color: $color-black;
  padding: $space-45 0;
  @media screen and (max-width: 575px) {
    padding: $space-3 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: $color-white;
  }

  &__titulo {
    font-size: 5rem;
    font-weight: $font-weight-bolder;
    line-height: 1.2;
    max-width: 890px;
    @media screen and (max-width: 767px) {
      font-size: $display-3;
    }
    @media screen and (max-width: 480px) {
      font-size: $header-1;
    }
    @media screen and (max-width: 375px) {
      font-size: $header-1-res;
    }
  }

  &__subtitulo {
    font-size: $header-3;
    margin-bottom: $space-3;
    @media screen and (max-width: 767px) {
      font-size: $header-3-res;
    }
  }

  &__actions {
    .boton {
      border: none;
      font-size: $header-2-res;
      font-weight: $font-weight-bold;
      padding-left: 0;
      &:hover {
        box-shadow: none;
      }
    }
  }
}
