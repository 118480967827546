.piedras {
  padding: $space-3 0;
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__content {
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0 $space-3;
    @media screen and (max-width: 1140px) {
      grid-template-columns: 1fr;
      gap: $space-01;
    }
  }
}

.piedra {
  padding: $space-3 0;
  border-bottom: 1px solid $color-black;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: $space-1;
  @media screen and (max-width: 675px) {
    grid-template-columns: 1fr;
    gap: $space-01;
  }

  &__link {
  }

  &__image {
    img {
      aspect-ratio: 4/3;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__titulo {
    a {
      font-size: $header-2-res;
      font-weight: $font-weight-bold;
      line-height: 1.5;
      color: $color-black;
    }
  }

  &__trabajos {
    line-height: 1;
    font-weight: $font-weight-normal;
    font-size: $font-size;
    color: $color-accent-2;
  }

  &__caracteristicas-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: $space-01 0;
  }

  &__caracteristicas-item {
    margin-bottom: $space-05;
    padding-left: $space-01;
    position: relative;
    &::before {
      position: absolute;
      left: 0;
      top: 8px;
      display: inline-block;
      content: "";
      color: $color-white;
      vertical-align: -0.125em;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-circle' viewBox='0 0 16 16'><path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/></svg>");
      background-repeat: no-repeat;
      background-size: 0.5rem 0.5rem;
      width: 0.5rem;
      height: 0.5rem;
    }
  }
}

.thumb-piedra {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: $space-2 0;
  border-bottom: 1px solid $color-black;
  &:nth-last-child(1) {
    border-bottom: none;
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__titulo {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    line-height: 1.5;
  }

  &__enlace {
    line-height: 1;
    a {
      font-weight: $font-weight-normal;
      font-size: $font-size;
      color: $color-accent-2;
    }
  }

  &__right {
  }

  &__image {
    img {
      aspect-ratio: 1.67;
      width: 100%;
      height: auto;
      object-fit: cover;
      max-width: 180px;
    }
  }
}

// Página Single piedras
.s-piedra {
  padding: $space-1 0 $space-3 0;
  &__content {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: $space-3;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $space-01;
    }
  }

  &__thumbnail {
    img {
      aspect-ratio: 4/3;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__titulo {
    font-size: $header-2-res;
    font-weight: $font-weight-bolder;
    margin-bottom: 0;
  }

  &__tit-acabados {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__tit-acabados-item {
    font-size: $header-3;
    margin-bottom: $space-1;
    display: flex;
    &::after {
      content: ",";
      margin-right: 0.5rem;
    }
    &:nth-last-child(1) {
      &::after {
        content: ".";
        margin-right: 0;
      }
    }
  }

  &__tipo {
    font-size: $header-3;
    margin-bottom: $space-1;
  }

  &__descripcion {
    margin-bottom: $space-01;
  }

  &__especificaciones {
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  &__especificaciones-title {
    text-transform: uppercase;
    color: $color-accent-2;
    font-size: $font-size-big;
    font-weight: $font-weight-bold;
  }

  &__especificaciones-items {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 675px) {
      grid-template-columns: 1fr;
    }
  }

  &__especificaciones-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: $space-01;
    position: relative;
    border-bottom: 1px solid $color-black;
    &::before {
      position: absolute;
      left: 0;
      top: 1.5rem;
      display: inline-block;
      content: "";
      color: $color-white;
      vertical-align: -0.125em;
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-circle' viewBox='0 0 16 16'><path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/></svg>");
      background-repeat: no-repeat;
      background-size: 0.5rem 0.5rem;
      width: 0.5rem;
      height: 0.5rem;
    }
    &:nth-child(odd) {
      padding-right: 3rem;
      @media screen and (max-width: 991px) {
        padding-right: 0;
      }
    }
    &:nth-child(even) {
      padding-left: 3rem;
      @media screen and (max-width: 991px) {
        padding-left: $space-01;
      }
      &::before {
        left: 2rem;
        @media screen and (max-width: 991px) {
          left: 0;
        }
      }
    }
  }
}

.s-piedra-acabados {
  padding: $space-3 0;
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__content {
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $space-3 $space-4;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-3;
    }
    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
      gap: $space-3;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-1;
    padding-bottom: $space-01;
    border-bottom: 1px solid $color-black;
  }

  &__thumbnail {
    img {
      aspect-ratio: 4/3;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__titulo {
    font-size: $header-3;
    font-weight: $font-weight-bold;
  }
}

.s-piedra-formatos {
  padding: $space-3 0 $space-5 0;
  @media screen and (max-width: 480px) {
    padding: $space-2 0 $space-3 0;
  }
  &__content {
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-3 $space-4;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-3;
    }
    @media screen and (max-width: 675px) {
      grid-template-columns: 1fr;
      gap: $space-3;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-1;
  }

  &__thumbnail {
    img {
      aspect-ratio: 1;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-1;
  }

  &__titulo {
    font-size: $header-3;
    font-weight: $font-weight-bold;
  }

  &__medidas {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__medida {
    font-size: 0.9rem;
  }
}
.h-proyectos {
  padding: $space-3 0;
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__items {
  }
}

.mas-piedras {
  background-color: $color-grey-6;
  padding: $space-3 0;
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__content {
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-4;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: $space-3;
    }
    @media screen and (max-width: 675px) {
      grid-template-columns: 1fr;
      gap: $space-3;
    }
    .thumb-piedra:nth-last-child(1) {
      border-bottom: 1px solid $color-black;
    }
  }
}
