.nav-primary {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & > li.menu-item {
      a {
        color: $color-grey-3;
        font-size: $font-size;
        text-decoration: none;
        padding: $font-size-smaller $font-size-small;
        @media screen and (max-width: 1400px) {
          padding-left: 14px;
          padding-right: 14px;
        }
        &:hover {
          color: $color-grey-2;
        }
        @media screen and (max-width: 1320px) {
          font-size: 0.95rem;
          padding-left: $font-size-smaller;
          padding-right: $font-size-smaller;
        }
      }
    }
  }
}

.wpml-ls-legacy-list-horizontal {
  .wpml-ls-link {
    display: inline-flex;
    padding: 3px;
    //border: 3px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    //margin-left: 5px;
    .wpml-ls-flag {
      border-radius: 4px;
      width: 24px;
      height: auto;
      border: 1px solid $color-grey-6;
    }
  }
}

#offcanvasMenu {
  width: 100vw;
  border: none;
}

.offcanvas {
  &-body {
    background-color: $color-white;
    padding-top: 0.5rem;
    button {
      display: flex;
      margin-left: auto;
      border: none;
      background: none;
      font-size: 3rem;
      line-height: 0;

      i {
        color: $color-black;
        &:hover {
          color: $color-accent-2;
        }
      }
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    li.menu-item {
      margin-bottom: 0.6rem;
      @media screen and (max-width: 575px) {
        margin-bottom: 0.3rem;
      }
      & > a {
        font-size: $header-2-res;
        font-weight: $font-weight-bolder;
        text-decoration: none;
        color: $color-black;
        &:hover {
          color: $color-accent-2;
        }
        @media screen and (max-width: 767px) {
          font-size: $header-3;
        }
      }
      ul.sub-menu {
        list-style: none;
        display: flex;
        justify-content: center;
        padding-left: 0;
        flex-wrap: wrap;
        li {
          margin: 0 5px 5px 0px;
          a {
            background-color: $color-white;
            color: $color-black !important;
            font-size: 1.5rem;
            padding: 4px 8px;
            border-radius: $border-radius-min;
            display: inline-block;
            font-weight: $font-weight-medium;
            transition: $trans;

            &:hover {
              background-color: $color-background !important;
              color: $color-black !important;
              transition: $trans;
            }
            @media screen and (max-width: 767px) {
              font-size: 1.25rem;
            }
            @media screen and (max-width: 480px) {
              font-size: 1.125rem;
            }
          }
        }
      }
    }
  }

  &__wpml {
    display: flex;
    justify-content: center;
    align-self: center;
    ul {
      display: flex;
      li {
        text-transform: uppercase;
        a {
          font-family: $typo-primary;
          font-size: $header-2-res;
          font-weight: $font-weight-bold;
          color: $color-white;
          opacity: 0.5;
          @media screen and (max-width: 767px) {
            font-size: $header-3;
          }
          &:hover {
            color: $color-accent;
            opacity: 1;
          }
        }
        &.wpml-ls-current-language {
          a {
            opacity: 1;
            cursor: default;
          }
        }
      }
    }
  }

  &__rs {
    display: flex;
    justify-content: center;
    margin-bottom: $space-1;

    .social-icon {
      font-size: 32px !important;
      color: $color-black !important;
      @media screen and (max-width: 767px) {
        font-size: 28px !important;
      }
      &:hover {
        color: $color-accent-2 !important;
      }
    }
  }
  &__logo {
    img {
      display: flex;
      width: 220px;
      margin: 1rem auto 0 auto;
      @media screen and (max-width: 575px) {
        width: 200px;
      }
      @media screen and (max-width: 420px) {
        width: 160px;
      }
    }
  }
}

#menu-menulegal,
#menu-menulegal-ingles,
#menu-menulegal-frances,
#menu-menulegal-catalan {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: $space-01;
  & > li.menu-item {
    a {
      font-size: $font-size-small;
      text-decoration: none;
      line-height: 1.5;

      color: $color-black;
      &:hover {
        color: $color-accent-2;
      }
    }
  }
}
