@mixin button {
  padding: 14px 24px; // cambiar tamaño
  font-size: 1rem;
  border-radius: 0; // cambiar radius
  font-weight: $font-weight-medium;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  &--lg {
    padding: 1.125rem 1.9375rem;
    @media screen and (max-width: 767px) {
      padding: 1rem 1.475rem;
    }
  }
  &--sm {
    padding: 7px 13px;
    font-size: $font-size-small;
  }
}
