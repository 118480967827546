.slick-list {
  // border-radius: 3rem;
  padding-bottom: $space-1 !important;
  // padding-top: $space-1 !important;
  // padding-left: 280px !important;
  // padding-right: 0 !important;
  height: 620px;
  @media screen and (max-width: 991px) {
    padding-bottom: $space-01 !important;
    padding-top: $space-01 !important;
    // padding-left: 200px !important;
  }
  // @media screen and (max-width: 480px) {
  //   padding-left: 0 !important;
  // }
}

.slick-center {
}

.slick-dots {
  margin-top: $space-1 !important;
  @media screen and (max-width: 991px) {
    margin-top: $space-01 !important;
  }
}

.slick-dots li {
  @media screen and (max-width: 575px) {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
}

.slick-dots li.slick-active button::before {
  opacity: 0.75 !important;
  color: $color-primary !important;
}

.slick-dots li button::before {
  font-size: 2rem !important;
  opacity: 0.25 !important;
  color: $color-primary !important;
  @media screen and (max-width: 767px) {
    font-size: $font-size * 2 !important;
  }
}

.slick-arrow {
  width: 64px !important;
  height: 64px !important;
  z-index: 1;
  background-color: $color-white !important;
  padding: 0.25rem;
  border-radius: $space-3;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.slick-prev {
  left: 2rem !important;
  mask-image: url("../assets/ico/circle-chevron-left-solid.svg");
  mask-size: 100%;
  mask-repeat: no-repeat;
  display: none !important;
}

.slick-next {
  right: 2rem !important;
  mask-type: alpha;
  mask-composite: subtract;
  mask-image: url("../assets/ico/circle-chevron-right-solid.svg");
  mask-size: 100%;
  mask-repeat: no-repeat;
}

.slick-prev::before,
.slick-next::before {
  color: $color-black !important;
  content: "" !important;
  opacity: 1 !important;
}
