.hero {
  position: relative;
  overflow: hidden;
  background-color: $color-fondo-hero;
  min-height: 700px;
  display: flex;
  justify-content: center;
  transition: $trans;

  @media screen and (max-width: 767px) {
    min-height: 500px;
    transition: $trans;
  }
  @media screen and (max-width: 480px) {
    min-height: 420px;
    transition: $trans;
  }
  &__img {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
    transition: $trans;
  }

  &__overlay {
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    opacity: 0.33;
  }

  &__container {
    display: flex;
    align-items: flex-end;
    height: 100%;
    position: absolute;
  }

  &__content {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    color: $color-white;
    padding-bottom: $space-3;
  }

  &__pretitulo {
    font-size: $header-1;
    font-weight: $font-weight-bolder;
    color: $color-white;
    transition: $trans;

    // @media screen and (max-width: 1199px) {
    //   font-size: $header-3;
    // }

    @media screen and (max-width: 575px) {
      margin-bottom: $font-size-smaller;
      font-size: $header-1-res;
      transition: $trans;
    }
  }

  &__titulo {
    font-size: $display-1;
    font-weight: $font-weight-bolder;
    line-height: 0.9;
    color: $color-white;
    transition: $trans;

    // @media screen and (max-width: 1199px) {
    //   font-size: $display-3;
    //   // br {
    //   //   display: none;
    //   // }
    // }
    @media screen and (max-width: 810px) {
      font-size: $display-2;
      transition: $trans;
    }
    @media screen and (max-width: 575px) {
      margin-bottom: $font-size;
      font-size: $display-3;
      transition: $trans;
    }
  }

  &__img {
    width: 100%;
    height: auto;
  }
}
