.as-blog {
  padding: $space-4 0;
  @media screen and (max-width: 991px) {
    padding: $space-2 0;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 3rem 2rem;

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

.noticia {
  &__image {
    img {
      aspect-ratio: 16/11;
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: $space-01;
    }
  }

  &__data {
    color: $color-primary;
    font-size: $header-4;
    font-weight: $font-weight-bold;
  }

  &__titol {
    color: $color-accent;
    font-size: $display-4;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
    @media screen and (max-width: 575px) {
      //font-size: $header-3-res;
    }
  }
}

////////////////
// Single
/////////////
.entrada {
  padding: $space-1 0 $space-3 0;
  &__container {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 70px 1fr 70px;
    gap: $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: 40px 1fr;
    }
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $space-1;
    }
  }

  &__aside {
  }

  &__content {
  }

  &__thumbnail {
    margin-bottom: $space-2;
    img {
      width: 100%;
      height: auto;
    }
  }

  .post__cat {
    margin-top: 0;
  }
}

.share-post {
  position: sticky;
  top: $space-5;
  margin-top: $font-size-smaller;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }
  @media screen and (max-width: 575px) {
    flex-direction: row;
    align-items: center;
  }
  &__item {
    margin-bottom: $font-size;
    @media screen and (max-width: 991px) {
      margin-right: 0.75rem;
      margin-bottom: 0;
    }
  }

  svg {
    width: 42px;
    height: auto;
    path:first-child {
      fill: $color-primary !important;
      transition: $trans;
    }
    &:hover {
      path:first-child {
        fill: $color-dark !important;
        transition: $trans;
      }
    }
  }
}

.relacionadas {
  padding: $space-3 0;
  background-color: $color-grey-6;
  &__content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 3rem 1.875rem;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 575px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
