.page-title {
  position: relative;
  overflow: hidden;
  background-color: $color-white;
  min-height: 700px;
  display: flex;
  justify-content: center;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      object-fit: cover;
      min-width: 100vw !important;
      height: 100% !important;
      transition: $trans;
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding: $space-3 $font-size;
    align-self: flex-end;
  }

  &__content {
    width: 100%;
    background-color: $color-white;
    padding: $space-1 $space-2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-3;
    @media screen and (max-width: 1099px) {
      grid-template-columns: 1fr 2fr;
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: $font-size;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__breadcrumbs {
    font-weight: $font-weight-normal;
    color: $color-grey-2;
    a {
      font-weight: $font-weight-bold;
    }
  }

  &__h {
    font-size: $header-1-res;
    font-weight: $font-weight-bolder;
  }

  &__right {
  }

  &__description {
    font-size: $display-3;
    font-weight: $font-weight-bolder;
    line-height: 1;
    strong {
      font-weight: $font-weight-bolder;
      color: $color-accent-2;
    }
    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
    }
  }

  &--nosotros {
  }

  &--piedras,
  &--proyectos {
    min-height: 360px;
    // @media screen and (max-width: 640px) {
    //   min-height: 400px;
    // }
    // @media screen and (max-width: 480px) {
    //   min-height: 445px;
    // }
  }

  &--contacto {
    min-height: 255px;
  }

  &--piedra,
  &--proyecto {
    min-height: auto;
    .page-title__background,
    .page-title__h,
    .page-title__right {
      display: none;
    }
    .page-title__content {
      grid-template-columns: 1fr;
      padding: 0;
    }
    .page-title__container {
      padding-top: $space-1;
      padding-bottom: $font-size;
    }
  }

  &--legal {
    min-height: 150px;
    .page-title__background,
    .page-title__right {
      display: none;
    }
    .page-title__content {
      grid-template-columns: 1fr;
      padding-left: 0;
      padding-bottom: 0;
    }
    .page-title__container {
      padding-bottom: $font-size;
    }
  }

  &--post {
    min-height: 300px;
  }
}

.single-post {
  .page-title__breadcrumbs {
    text-align: center;
  }
}
