.boton {
  @include button;
}

$buttons: (
  // nom, color fondo, color texto, color texto-outline
  "primary" $color-primary $color-white $color-grey-2,
  "secondary" $color-secondary $color-white $color-grey-2,
  "accent" $color-accent $color-white $color-accent,
  "accent-2" $color-accent-2 $color-white $color-accent-2,
  "white" $color-white $color-grey-2 $color-grey-2,
  "dark" $color-black $color-white $color-grey-3,
  "whatsapp" $color-whatsapp-light $color-white $color-grey-2,
  "text" transparent $color-black $color-black
);

@each $name, $c-background, $c-text, $c-text-outline in $buttons {
  .boton--#{$name} {
    background: $c-background;
    color: $c-text;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }

  .boton--#{$name}-outline {
    // background: $c-background;
    color: $c-text-outline;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      border-color: darken($c-background, 5%);
      color: $c-text-outline;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }
}

.boton--text {
  font-size: $header-3;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 11px;
  position: relative;
  &:hover {
    transform: none;
    box-shadow: none;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      border-bottom: solid 2px #000;
      animation: border_anim 0.25s ease-in-out forwards;
    }
  }
}

@keyframes border_anim {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
