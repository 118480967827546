.certificados {
  padding: $space-5 0;
  background-color: $color-grey-6;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__content {
  }

  &__galeria {
  }

  &__galeria-slider {
  }

  &__galeria-slider-item {
    width: max-content !important;
  }

  &__galeria-img {
    height: 600px;
    width: auto;
    object-fit: cover;
    padding: 0 0.5rem;
  }

  &__items {
    padding-top: $space-4 - $space-1;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      padding-top: $space-3 - $space-1;
    }
    @media screen and (max-width: 480px) {
      padding-top: $space-2 - $space-1;
    }
  }

  &__item {
    padding: $font-size 0;
    border-top: 2px solid $color-black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $text-body-big;
    transition: $trans;
    &:nth-last-child(1) {
      border-bottom: 2px solid $color-black;
    }
    @media screen and (max-width: 575px) {
      font-size: $font-size-big;
      transition: $trans;
    }
  }

  &__info {
  }

  &__titulo {
  }

  &__detalls {
    @media screen and (max-width: 575px) {
      display: none;
    }
  }

  &__format {
  }

  &__descargar {
    i {
      font-size: 2rem;
      line-height: 1;
      color: $color-black;
      @media screen and (max-width: 575px) {
        font-size: 1.5rem;
      }
    }
  }
}
