.footer {
  background-color: $color-white;
  box-shadow: 0 50vh 0 50vh $color-white;
  &__content {
    padding: $space-3 0;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $space-1;
      text-align: center;
    }
  }

  &__item {
    color: $color-black;
    .widget-footer__title {
      font-size: 1.375rem;
      font-weight: $font-weight-bold;
    }
  }

  &__item--logo {
    img {
      width: 100%;
      height: auto;
      max-width: 116px;
    }
  }

  &__item--info {
  }

  &__item--contacto {
  }

  &__item--rs {
    ul li:nth-child(1) {
      .social-icon {
        padding-left: 0 !important;
      }
    }
  }
  .zoom-social-icons-list {
    margin-left: 0 !important;
    display: flex;
    justify-content: flex-start;
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
  }
  .social-icon {
    font-size: 24px !important;
    color: $color-black !important;
    @media screen and (max-width: 800px) {
      font-size: 20px !important;
    }
    &:hover {
      color: $color-accent-2 !important;
      border-color: $color-accent-2;
    }
  }

  &__copyright {
    padding: $space-1 0;
    border-top: 1px solid $color-black;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $space-01;
    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $space-05;
      text-align: center;
    }
    i {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  &__copy {
    font-size: $font-size-small;
  }

  &__nav-legal {
  }
}
