.header {
  padding: $font-size 0;
  background-color: $color-white;
  width: 100%;
  z-index: 99;
  position: sticky;
  top: 0;
  transition: $trans;

  @media screen and (max-width: 575px) {
    padding-top: $font-size;
    padding-bottom: $font-size;
  }

  &--sticky {
    padding: $font-size $space-1;
    box-shadow: $box-shadow;
    transition: $trans;

    .logo {
      width: 226px !important;
      transition: $trans;
    }
    .header__burger {
      i {
        font-size: 2.2rem;
        transition: $trans;
      }
    }
  }

  &__container {
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-2;
    @media screen and (max-width: 1400px) {
      gap: $space-1;
    }
    @media screen and (max-width: 375px) {
      gap: $space-01;
    }
  }

  &__logo {
    .logo {
      width: 260px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 575px) {
        width: 180px;
        transition: $trans;
      }
    }
  }

  &__wpml {
    align-self: center;
    ul {
      display: flex;
      li {
        text-transform: uppercase;
        a {
          font-family: $typo-primary;
          font-weight: $font-weight-bold;
          color: $color-grey-3;
          opacity: 0.5;
          &:hover {
            color: $color-primary;
            opacity: 1;
          }
        }
        &.wpml-ls-current-language {
          a {
            opacity: 1;
            cursor: default;
          }
        }
      }
    }
    @media screen and (max-width: 1310px) {
      margin-left: auto;
    }
    // @media screen and (max-width: 480px) {
    //   display: none;
    // }
  }

  &__nav-primary {
    margin-left: auto;
    transition: $trans;
    @media screen and (max-width: 767px) {
      display: none;
      transition: $trans;
    }
  }

  &__burger {
    transition: $trans;
    i {
      color: $color-grey-3;
      font-size: 2.5rem;
      transition: $trans;
      line-height: 1;
      &:hover {
        color: $color-grey-2;
      }
    }
    line-height: 1;
    @media screen and (min-width: 768px) {
      display: none;
      transition: $trans;
    }
  }
}
