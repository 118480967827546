.page,
.post {
  &__entry {
    line-height: 1.7;
    p,
    ul,
    ol,
    blockquote {
      a {
        color: $color-black !important;
        &:hover {
          color: $color-accent-2 !important;
        }
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.6;
      margin-bottom: 1.5rem;
    }
    p,
    ol,
    ul {
      font-size: 1.1rem;
      @media print {
        font-size: 10pt;
      }
    }
    p,
    ol,
    ul,
    blockquote {
      margin-bottom: 1.5rem;
    }

    ul {
      li {
        margin-bottom: 10px;
      }
    }

    h2 {
      font-size: 2.2rem;
      color: $color-accent !important;
      font-weight: 700;
      @media screen and (max-width: 575px) {
        font-size: 2rem;
        line-height: 1.3;
      }
      & strong {
        font-weight: 400;
      }
    }
    h3 {
      font-size: 1.5rem;
      color: $color-accent-2;
      @media screen and (max-width: 575px) {
        font-size: 1.4rem;
        line-height: 1.3;
      }
    }
    strong {
      //color: $color-grey-2;
    }

    em {
      font-style: normal;
      //color: $color-primary;
    }

    .wp-block-pullquote {
      padding: 2rem 0 !important;
      text-align: left !important;
      margin-bottom: 0 !important;
    }
    .wp-block-image {
      margin: 2rem 0;

      @media print {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: $border-radius !important;
        box-shadow: $box-shadow;
      }
      figcaption {
        text-align: center;
        font-size: $font-size-smaller;
      }
    }
    .wp-block-gallery {
      margin: 1.8rem 0;
      .blocks-gallery-item {
        margin-bottom: 0;
      }
      img {
        width: 100%;
        height: auto;
      }
      figcaption {
        text-align: center;
      }
    }
    .block-wikiloc {
      iframe {
        width: 100%;
        margin-top: 2rem !important;
        margin-bottom: 2rem;
        box-shadow: $box-shadow;
        border-radius: 0.5rem;
      }
      div {
        display: none;
      }
    }
  }
  .wp-block-gallery.has-nested-images
    figure.wp-block-image:not(#individual-image) {
    @media screen and (max-width: 480px) {
      width: calc(100% - var(--wp--style--unstable-gallery-gap, 16px) / 2);
    }
  }
}
