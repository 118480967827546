.contacto {
  padding: $space-5 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }

  &__descripcion {
    margin-bottom: $space-3;
    line-height: 1.2;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-01;
    }
  }

  &__content {
    //color: $color-white;
    display: grid;
    grid-template-columns: 1fr 2fr;
    @media screen and (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__col1 {
    @media screen and (max-width: 991px) {
      margin-bottom: $space-2;
    }
  }

  &__contenido {
    @media screen and (max-width: 991px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: $space-1;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: $space-01;
    }
  }

  &__col2 {
    padding-left: $space-4;
    display: flex;
    flex-direction: column;
    //color: $color-white;

    @media screen and (max-width: 991px) {
      padding-left: 0;
    }
  }

  &__direccion {
    margin-bottom: $space-01;
  }
  &__horario {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-01;
    @media screen and (max-width: 480px) {
      margin-bottom: 0;
      p {
        margin-bottom: 0;
      }
    }
  }

  &__telefonos {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-01;
    font-size: $font-size;
    @media screen and (max-width: 991px) {
      margin-bottom: $space-01;
    }
    a {
      color: $color-black !important;
      text-decoration: none;
      &:hover {
        color: $color-accent-2 !important;
      }
    }
    strong {
      margin-bottom: 0.25rem;
    }
  }

  &__telefonos-item {
    display: flex;
    margin-bottom: 0.2rem;
    .bi {
      margin-right: 0.5rem;
    }
    a {
      color: $color-dark;
      &:hover {
        color: $color-primary;
      }
    }
    &:nth-last-child(1) {
      margin-top: 0.25rem;
      margin-bottom: 0;
    }
  }

  &__social {
    .zoom-social-icons-list {
      margin-left: 0 !important;
      display: flex;
      justify-content: flex-start;
    }
    .social-icon {
      font-size: 28px !important;
      color: $color-primary !important;
      border: 1px solid $color-primary;
      @media screen and (max-width: 767px) {
        font-size: 26px !important;
      }
      &:hover {
        color: $color-accent !important;
        border-color: $color-accent;
      }
    }
  }
}

.ubicacion {
  iframe {
    width: 100%;
  }
}
