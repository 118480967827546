html {
  scroll-behavior: smooth;
}
body {
  background-color: $color-background;
  font-size: $font-size;
  color: $color-text-body;
  font-weight: $font-weight-normal;
  font-family: $typo-primary;

  /* Font Smoothing */
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

a {
  font-family: $typo-primary;
  color: $color-black;
  text-decoration: none;

  transition: $trans;
  &:hover {
    color: $color-accent-2;
    text-decoration: none;
    transition: $trans;
  }
}
