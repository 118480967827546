.nuestros-proyectos {
  padding-top: $space-5;
  @media screen and (max-width: 767px) {
    padding-top: $space-3;
  }
  @media screen and (max-width: 480px) {
    padding-top: $space-2;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__descripcion {
    font-size: $text-body-big;
    @media screen and (max-width: 767px) {
      font-size: $font-size-bigger;
    }
  }
}

.proyectos {
  padding: $space-5 0;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  @media screen and (max-width: 480px) {
    padding: $space-2 0;
  }
  &__content {
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-1;
    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
}

.thumb-proyecto {
  position: relative;
  &:hover {
    .thumb-proyecto__overlay {
      opacity: 1;
      transition: $trans;
    }
  }

  &__image {
    position: relative;
    // width: max-content;
    img {
      aspect-ratio: 1;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0 $space-2;
    background-color: rgba($color-black, 0.15);
    opacity: 0;
    transition: $trans;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__info {
    background-color: $color-white;
    padding: $space-05 $space-1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    color: $color-black;
    > div {
      width: 100%;
      border-bottom: 1px solid $color-black;
      padding: 0.25rem 0;
      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }

  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: $space-01;
  }

  &__titulo {
    font-size: $header-3;
    font-weight: $font-weight-bold;
  }

  &__acabado {
    color: $color-accent-2;
  }

  &__localizacion {
  }

  &__anyo {
  }
}

// Página single proyectos
.s-proyecto {
  &__content {
  }

  &__info {
    padding-bottom: $space-3;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 0;
    }
  }

  &__descripcion {
    font-size: $text-body-big;
    @media screen and (max-width: 767px) {
      font-size: $font-size-bigger;
    }
  }

  &__caracteristicas {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (min-width: 768px) {
      grid-column: 2 / span 1;
    }
  }

  &__caracteristicas-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $font-size;
    margin-bottom: 0.75rem;
    border-bottom: 1px solid $color-black;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  &__caracteristicas-titulo {
    font-size: $font-size-big;
    font-weight: $font-weight-bold;
  }

  &__caracteristicas-descripcion {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__caracteristicas-desc-item {
    &::after {
      content: ",";
      margin-right: 0.5rem;
    }
    &:nth-last-child(1)::after {
      content: ".";
      margin-right: 0;
    }
  }

  &__thumbnail {
    img {
      width: 100vw;
      height: auto;
      max-height: 770px;
      object-fit: cover;
    }
  }

  &__galeria {
    margin: 4px 0;

    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 4px;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (max-width: 480px) {
      grid-template-columns: 1fr;
    }
  }

  &__galeria-item {
  }

  &__galeria-img {
    aspect-ratio: 1;
    width: 100%;
    object-fit: cover;
  }
}

.mas-proyectos {
  padding: $space-5 0;
  background-color: $color-grey-6;
  &__content {
  }

  &__items {
  }
}
